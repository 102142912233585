import { createStore, combineReducers } from "redux";
import navigationReducer from "../reducers/navigationReducer";

const rootReducer = combineReducers({
  navigationReducer: navigationReducer,
});
const configureStore = () => {
  return createStore(rootReducer);
};
export default configureStore;

export const store = configureStore();
