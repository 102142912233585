import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import Image from "../../elements/Image";
import logo from "./../../../assets/icons/Stacked Dark.svg";
import logoAz from "./../../../assets/icons/Stacked Dark Az.svg";
import { useSelector } from "react-redux";

const Logo = ({ className, ...props }) => {
  const classes = classNames("brand", className);
  let language = useSelector((state) => state.navigationReducer.language);

  return (
    <div {...props} className={classes} style={{ zIndex: 100 }}>
      <h1 className="m-0">
        <Link to="/">
          <Image
            src={language === "az" ? logoAz : logo}
            width={140}
            height={140}
          />
        </Link>
      </h1>
    </div>
  );
};

export default Logo;
