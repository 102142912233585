import React from "react";
import classNames from "classnames";
import { routes } from "../../../assets/static/router";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const HeaderNav = ({ className, onClick }) => {
  let [, setHovered] = React.useState(false);
  const { t } = useTranslation();
  let isLogged = useSelector((state) => state.navigationReducer.bearerToken);

  return (
    <>
      <div className="underline"></div>

      {routes
        .filter((route) => {
          return (isLogged && route.index === 3) || route.index !== 3;
        })
        .map((route, index) => (
          <Link
            to={route.path}
            onClick={(e) => {
              onClick(route.index);
              console.log(e.target);
            }}
            className="menu-item-link"
          >
            <div
              className={classNames("a-header-nav nav-service", className)}
              onMouseEnter={() => route.index === 2 && setHovered(true)}
              onMouseLeave={() => setHovered(false)}
              key={index}
            >
              {t(route.name)}
            </div>
          </Link>
        ))}
    </>
  );
};

export default HeaderNav;
