import logo from "./logo.svg";
import "./App.css";
import Routes from "./Routes";
import { Provider } from "react-redux";
import { store } from "./store";
import React, { useEffect } from "react";
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <Provider store={store}>
      <Routes />
      <ToastContainer />
    </Provider>
  );
}

export default App;
