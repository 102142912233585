import React from "react";

import close from "../../../assets/images/map-image/modal-icons/close-button.svg";
import { useTranslation } from "react-i18next";

const ActionModal = ({
  title,
  body,
  type,
  onCancel,
  onSuccess,
  isCancellable,
}) => {
  const { t } = useTranslation();

  return (
    <div className={"modal-holder"}>
      <img
        className={"modal-close-button"}
        src={close}
        alt="modal"
        width={"30x"}
        height={"30px"}
        onClick={onCancel}
      ></img>

      <p className="modal-title">{title}</p>
      <p className="modal-body">{body}</p>
      <div className={"modal-buttons-holder"}>
        {isCancellable && (
          <a className={"modal-cancel-button"} onClick={onCancel} href="#">
            {t("cancel")}
          </a>
        )}
        <a
          className={"button_primary"}
          style={{
            color: "#007aff",
            display: "flex",
            alignItems: "center",
            marginBottom: 0,
            height: "100%",
          }}
          onClick={onSuccess}
          href="#"
        >
          {t("ok")}
        </a>
      </div>
    </div>
  );
};

export default ActionModal;
